import { Text } from "react-native"

type BrProps = {
  small?: boolean
}

export default function Br(props: BrProps) {
  if (props.small) {
    return <Text>{"\n"}</Text>
  }
  return (
    <Text>
      {"\n"}
      {"\n"}
    </Text>
  )
}
