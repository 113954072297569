import { createStackNavigator } from "@react-navigation/stack"
import BasicInfoCollection from "~/pages/BasicInfoCollection"
import ClinicalResults from "~/pages/ClinicalResults"
import Home from "~/pages/Home"
import MorningReview from "~/pages/MorningReview"
import MorningReviewAnswer from "~/pages/MorningReviewAnswer"
import ClinicalResultsAnswer from "~/pages/ClinicalResultsAnswer"
import { User } from "~/types/User"
import { Review } from "~/types/Review"
import { ClinicalResult } from "~/types/ClinicalResult"
import ClinicalResultsView from "~/pages/ClinicalResultsView"
import CKDInfoPage from "~/pages/CKDInfoPage"

export type AppStackParamList = {
  Home: undefined
  MorningReview: undefined
  MorningReviewAnswer: Review.Details
  RequiredBasicInfoCollection: undefined
  BasicInfoCollection: User.Details
  ClinicalResults: undefined
  ClinicalResultsAnswer: ClinicalResult.Details
  ClinicalResultsView: undefined
  CKDInfoPage: undefined
}

const { Navigator, Screen } = createStackNavigator<AppStackParamList>()

export default function AppStack() {
  return (
    <Navigator>
      <Screen name="Home" component={Home} options={{ headerShown: false }} />
      <Screen
        name="MorningReviewAnswer"
        component={MorningReviewAnswer}
        options={{ headerTitle: "Morning Report", animationEnabled: true }}
      />
      <Screen
        name="MorningReview"
        component={MorningReview}
        options={{ headerTitle: "Morning Review", animationEnabled: true }}
      />
      <Screen
        name="RequiredBasicInfoCollection"
        component={BasicInfoCollection}
        options={{
          headerTitle: "Account Setup",
          headerLeft: () => <></>,
          animationEnabled: true
        }}
      />
      <Screen
        name="BasicInfoCollection"
        component={BasicInfoCollection}
        options={{ headerTitle: "Account Setup", animationEnabled: true }}
      />
      <Screen
        name="ClinicalResults"
        component={ClinicalResults}
        options={{
          headerTitle: "Clinical Test Results",
          animationEnabled: true
        }}
      />
      <Screen
        name="ClinicalResultsAnswer"
        component={ClinicalResultsAnswer}
        options={{
          headerTitle: "Clinical Test Report",
          animationEnabled: true
        }}
      />
      <Screen
        name="ClinicalResultsView"
        component={ClinicalResultsView}
        options={{
          headerTitle: "Clinical Test History",
          animationEnabled: true
        }}
      />
      <Screen
        name="CKDInfoPage"
        component={CKDInfoPage}
        options={{
          headerTitle: "infomation on CKD",
          animationEnabled: true
        }}
      />
    </Navigator>
  )
}
