import { useEffect, useState } from "react"
import { Text, View } from "react-native"
import TextInput from "./TextInput"
import NumberInput from "./NumberInput"
import SelectDropdown from "react-native-select-dropdown"
import SegmentedControl from "@react-native-segmented-control/segmented-control"

type BaseInputProps = {
  type: string
  title?: string
  required?: boolean
  errors?: [boolean, string][]
  className?: string
}

type NumberInputProps = {
  type: "NUMBER"
  placeholder: string
  value?: number
  setValue: (value: number) => void
  int?: boolean
}

type TextInputProps = {
  type: "TEXT" | "EMAIL" | "PASSWORD"
  placeholder: string
  value?: string
  setValue: (value: string) => void
}

type DateInputProps = {
  type: "DATE"
  ignore?: ("DATE" | "MONTH" | "YEAR")[]
  value?: Date
  setValue: (value: Date) => void
}

type SegmentedInputProps = {
  type: "SEGMENTED"
  value?: number
  setValue: (value: number) => void
  values: string[]
}

type DropdownInputProps = {
  type: "DROPDOWN"
  value: number
  setValue: (value: number) => void
  values: string[]
  defaultValue: number
}

type CustomInputProps = {
  type: "CUSTOM"
  children: React.ReactNode
}

type BloodPressureInputProps = {
  type: "BLOOD_PRESSURE"
  upper?: number
  setUpper: (value: number) => void
  lower?: number
  setLower: (value: number) => void
}

type InputProps = BaseInputProps &
  (
    | NumberInputProps
    | TextInputProps
    | DateInputProps
    | SegmentedInputProps
    | DropdownInputProps
    | CustomInputProps
    | BloodPressureInputProps
  )

export default function Input(props: InputProps) {
  const [dateOfBirth, setDateOfBirth] = useState<{
    date: number | null
    month: number | null
    year: number | null
  }>({
    date: null,
    month: null,
    year: null
  })

  useEffect(() => {
    if (props.type === "DATE") {
      setDateOfBirth({
        date: !props.ignore?.includes("DATE") ? props.value?.getDate() : null,
        month: !props.ignore?.includes("DATE")
          ? props.value?.getMonth() + 1
          : null,
        year: !props.ignore?.includes("DATE")
          ? props.value?.getFullYear()
          : null
      })
    }
  }, [])

  useEffect(() => {
    if (props.type === "DATE") {
      const missing = [
        dateOfBirth.date === null && !props.ignore?.includes("DATE"),
        dateOfBirth.month === null && !props.ignore?.includes("MONTH"),
        dateOfBirth.year === null && !props.ignore?.includes("YEAR")
      ]

      if (missing.every(v => !v))
        props.setValue(
          new Date(
            dateOfBirth.year ?? 0,
            dateOfBirth.month - 1 ?? 0,
            dateOfBirth.date ?? 1
          )
        )
    }
  }, [dateOfBirth])

  const input: React.ReactNode = (() => {
    // Text input
    if (
      ((props: InputProps): props is BaseInputProps & TextInputProps =>
        ["TEXT", "EMAIL", "PASSWORD"].includes(props.type))(props)
    )
      return (
        <TextInput
          value={props.value}
          onChangeText={e => props.setValue(e)}
          placeholder={props.placeholder}
          keyboardType={props.type === "EMAIL" ? "email-address" : undefined}
          secureTextEntry={props.type === "PASSWORD"}
          autoComplete={
            props.type === "EMAIL"
              ? "email"
              : props.type === "PASSWORD"
              ? "password"
              : undefined
          }
        />
      )

    // Number input
    if (
      ((props: InputProps): props is BaseInputProps & NumberInputProps =>
        ["NUMBER"].includes(props.type))(props)
    )
      return (
        <NumberInput
          value={props.value}
          onChangeText={e => props.setValue(e)}
          placeholder={props.placeholder}
          int={props.int}
        />
      )

    // Date input
    if (
      ((props: InputProps): props is BaseInputProps & DateInputProps =>
        ["DATE"].includes(props.type))(props)
    )
      return (
        <View className="flex flex-row gap-x-3">
          {!props.ignore?.includes("DATE") && (
            <NumberInput
              onChangeText={e =>
                setDateOfBirth(res => ({
                  ...res,
                  date: e
                }))
              }
              value={dateOfBirth.date}
              placeholder="dd"
              className="w-full"
              int
            />
          )}
          {!props.ignore?.includes("MONTH") && (
            <NumberInput
              onChangeText={e =>
                setDateOfBirth(res => ({
                  ...res,
                  month: e
                }))
              }
              value={dateOfBirth.month}
              placeholder="mm"
              className="w-full"
              int
            />
          )}
          {!props.ignore?.includes("YEAR") && (
            <NumberInput
              onChangeText={e =>
                setDateOfBirth(res => ({
                  ...res,
                  year: e
                }))
              }
              value={dateOfBirth.year}
              placeholder="yyyy"
              className="w-full"
              int
            />
          )}
        </View>
      )

    // Segmented input
    if (
      ((props: InputProps): props is BaseInputProps & SegmentedInputProps =>
        ["SEGMENTED"].includes(props.type))(props)
    )
      return (
        <SegmentedControl
          values={props.values}
          selectedIndex={props.value}
          onChange={e => props.setValue(e.nativeEvent.selectedSegmentIndex)}
        />
      )

    // Dropdown input
    if (
      ((props: InputProps): props is BaseInputProps & DropdownInputProps =>
        ["DROPDOWN"].includes(props.type))(props)
    )
      return (
        <SelectDropdown
          data={props.values}
          onSelect={(_, value) => props.setValue(value)}
          buttonTextAfterSelection={selectedItem => selectedItem}
          rowTextForSelection={item => item}
          defaultButtonText="No choice selected"
          buttonStyle={{
            borderRadius: 12,
            borderWidth: 2,
            borderColor: "#ddd",
            borderStyle: "solid",
            padding: 5,
            width: "100%"
          }}
          rowStyle={{
            padding: 15
          }}
          dropdownStyle={{
            width: 250,
            height: "auto"
          }}
          defaultValueByIndex={props.defaultValue}
        />
      )

    // Custom input
    if (
      ((props: InputProps): props is BaseInputProps & CustomInputProps =>
        ["CUSTOM"].includes(props.type))(props)
    )
      return props.children

    // Blood pressure input
    if (
      ((props: InputProps): props is BaseInputProps & BloodPressureInputProps =>
        ["BLOOD_PRESSURE"].includes(props.type))(props)
    )
      return (
        <View className="flex flex-row gap-x-3">
          <NumberInput
            onChangeText={props.setUpper}
            value={props.upper}
            placeholder="Upper (Systolic)"
            className="w-full"
            int
          />
          <NumberInput
            onChangeText={props.setLower}
            value={props.lower}
            placeholder="Lower (Diastolic)"
            className="w-full"
            int
          />
        </View>
      )
  })()

  return (
    <View className={props.className}>
      {props.title && (
        <View className="ml-5 mb-2">
          <Text>
            {props.title}{" "}
            {props.required && (
              <Text className="text-red-500 font-bold">*</Text>
            )}
          </Text>
        </View>
      )}
      {input}
      {props.errors?.map(([visible, error]) => (
        <Text
          key={error}
          className={`mx-2 mt-1 text-red-500 ${visible ? "" : "hidden"}`}
        >
          {error}
        </Text>
      ))}
    </View>
  )
}
