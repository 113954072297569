import React from "react"
import { Text, View } from "react-native"

const facts: string[] = [
  "Processed foods high in sodium can be harmful if you suffer from kidney disease, so try to limit your intake as much as possible.",
  "Some medications, like nonsteroidal anti-inflammatory drugs (NSAIDs), can contribute to kidney damage.",
  "Anaemia is a common complication of CKD.",
  "Vitamin D deficiency is often observed in CKD patients.",
  "CKD can affect bone health, leading to conditions like renal osteodystrophy.",
  "CKD can be associated with sleep disturbances.",
  "Mental health issues, such as depression, are common in CKD patients.",
  "CKD can cause cognitive impairment.",
  "CKD is associated with an increased risk of bone fractures.",
  "Some herbal supplements may have adverse effects on kidney function.",
  "CKD can lead to impaired wound healing.",
  "Kidney damage can be caused by exposure to certain toxins and medications.",
  "CKD can cause changes in skin coloyr and texture.",
  "Genetic factors can contribute to the development of CKD.",
  "While CKD can lead to death, many people with the condition live long and happy lives after diagnosis.",
  "Processed foods high in sodium can be harmful if you suffer from kidney disease, so try to limit your intake as much as possible.",
  "CKD affects over 800 million people worldwide.",
  "An estimated 10% of the global population has CKD.",
  "CKD is more common in older adults.",
  "CKD is categorized into five stages based on the level of kidney function.",
  "Proper hydration is crucial for kidney health.",
  "Regular exercise can help manage CKD risk factors.",
  "Smoking is a risk factor for CKD.",
  "The kidneys play a vital role in regulating blood pressure.",
  "The kidneys filter about 120 to 150 quarts of blood each day.",
  "Obesity is a risk factor for the development and progression of CKD.",
  "CKD can be exacerbated by dehydration.",
  "9 out of 10 people with kidney disease are unaware they have it, and half of those with severely reduced kidney function (but not yet on dialysis) do not know they have kidney disease.",
  "Kidney disease is a silent killer, usually with no signs or symptoms until the late stages.",
  "The prevalence of CKD increases rapidly with age, affecting around 44% of people aged 75 and over.",
  "CKD is a largely under-diagnosed condition.",
  "Early detection through regular screenings can significantly slow the progression of CKD.",
  "Managing blood sugar and blood pressure is crucial for kidney health."
]

const factsAlcohol: string[] = [
  "Excessive alcohol consumption can lead to high blood pressure, a major risk factor for kidney disease.",
  "Chronic heavy drinking can directly damage the kidneys and interfere with their ability to filter blood.",
  "Alcohol dehydrates the body, which can strain the kidneys. Adequate hydration is crucial for kidney function.",
  "Individuals with alcohol-related liver disease may also develop kidney problems, known as alcoholic nephropathy.",
  "Alcohol can interact negatively with medications, potentially impacting kidney health."
]

const helpAlcohol: string[] = [
  "Establishing clear limits for alcohol consumption can help reduce your consumption.",
  "Review your alcohol intake. This can help raise awareness of drinking patterns and facilitate better decision-making.",
  "Share your goals with friends or family who can offer encouragement and accountability. Consider joining support groups or seeking professional help if needed.",
  "Replace drinking with healthier activities such as exercise, hobbies, or socializing without alcohol.",
  "Be conscious of the reasons you're drinking. Avoid using alcohol as a way to cope with stress or emotions."
]

const factsSmoke: string[] = [
  "The harmful substances in cigarette smoke can lead to inflammation and damage to the blood vessels in the kidneys.",
  "Smoking accelerates the progression of kidney disease in individuals with conditions like diabetes and hypertension.",
  "Second-hand smoke exposure may also contribute to kidney damage in nonsmokers.",
  "Quitting smoking has immediate and long-term benefits for kidney health, reducing the risk of disease progression.",
  "Smoking increases the risk of kidney cancer."
]

const helpSmoke: string[] = [
  "Join smoking cessation support groups to share experiences, receive encouragement, and stay motivated.",
  "Consult a healthcare professional.",
  "Seek counselling or behavioural therapy to address the psychological aspects of smoking and develop coping strategies.",
  "Develop a comprehensive plan to quit smoking, including setting a quit date, identifying triggers, and seeking support.",
  "Designate certain areas, such as your home or car, as smoke-free zones to gradually reduce smoking."
]

const factsDiet: string[] = [
  "Diets high in sugar and refined carbohydrates can contribute to obesity and diabetes, both risk factors for kidney disease.",
  "Protein needs may vary at different stages of CKD. In later stages, a lower-protein diet might be recommended to reduce the workload on the kidneys. However, getting enough high-quality protein is still crucial.",
  "A diet high in fiber from sources like whole grains, fruits, and vegetables can support overall digestive health. However, it's essential to balance your fiber intake.",
  "Controlling fluid intake is crucial for managing fluid balance in CKD. This includes not only beverages but also foods with high water content, like soups and certain fruits.",
  "Read food labels carefully and choose whole, fresh foods when possible.",
  "Reducing phosphate and potassium intake is often necessary for CKD patients."
]

const helpDiet: string[] = [
  "Plan balanced and nutritious meals in advance to avoid impulsive and unhealthy food choices.",
  "Use smaller plates, be mindful of portion sizes, and listen to your body's hunger and fullness cues.",
  "Keep a food diary to track eating habits and identify areas for improvement.",
  "Make gradual changes to your diet to increase the likelihood of long-term success. Small, sustainable adjustments are more manageable.",
  "Aim for a variety of colourful fruits and vegetables on your plate. Different colours often indicate different nutrients."
]

const factsExercise: string[] = [
  "Exercise promotes better blood pressure control, lowering the risk of kidney disease.",
  "Sedentary lifestyles are associated with an increased risk of kidney disease; staying active is crucial.",
  "Exercise contributes to improved cardiovascular health, which positively impacts kidney function.",
  "Both aerobic and resistance training exercises have been shown to benefit kidney health.",
  "Exercise can help manage stress, and chronic stress may contribute to kidney problems over time."
]

const helpExercise: string[] = [
  "Define achievable exercise goals and gradually increase intensity and duration over time.",
  "Engage in physical activities you enjoy to make exercise more sustainable.",
  "Establish a consistent exercise routine to make it a habit. Schedule workouts at times that fit your daily schedule.",
  "Exercise with a friend or join a fitness class to create accountability and motivation.",
  "Start with exercising once a week.",
  "Consult with a healthcare professional or physical therapist to design an exercise program that suits your individual needs and fitness level."
]

const General: string[] = [
  "Practices such as mindfulness meditation and stress reduction techniques can help manage the triggers associated with these behaviours.",
  "Seek advice from healthcare professionals, including doctors, dietitians, therapists, or fitness trainers, for personalized guidance and support.",
  "Seeking professional help, whether from a doctor, nutritionist, counsellor, or other specialists, can significantly enhance your chances of success in managing and controlling these aspects of your lifestyle.",
  "Regular health check-ups and screenings can help detect early signs of CKD and other related health issues.",
  "Education and awareness about CKD are crucial for early detection and management."
]

type Category =
  | "Home"
  | "alcohol"
  | "alcoholHelp"
  | "Smoke"
  | "SmokeHelp"
  | "Diet"
  | "DietHelp"
  | "Exercise"
  | "ExerciseHelp"

type FactProps = {
  children?: React.ReactNode
  categories: Category[]
}

export default function Fact({ categories, children }: FactProps) {
  let fact = []
  for (const category of categories) {
    switch (category) {
      case "Home":
        fact = fact.concat(facts)
        break
      case "alcohol":
        fact = fact.concat(factsAlcohol)
        break
      case "alcoholHelp":
        fact = fact.concat(helpAlcohol)
        break
      case "Smoke":
        fact = fact.concat(factsSmoke)
        break
      case "SmokeHelp":
        fact = fact.concat(helpSmoke)
        break
      case "Diet":
        fact = fact.concat(factsDiet)
        break
      case "DietHelp":
        fact = fact.concat(helpDiet)
        break
      case "Exercise":
        fact = fact.concat(factsExercise)
        break
      case "ExerciseHelp":
        fact = fact.concat(helpExercise)
        break
      default:
        break
    }
  }

  const index = Math.floor(Math.random() * fact.length)
  return (
    <View className="p-5 bg-gray-200 rounded">
      <Text className="text-lg italic mb-3">Did you know...</Text>
      <Text>{fact[index]}</Text>
    </View>
  )
}
