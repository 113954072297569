import useCanvas from "~/hooks/useCanvas"

export type HeatmapDataEntry = {
  egfr: number
  acr: number
  isNew: boolean
}

type HeatmapProps = {
  values: HeatmapDataEntry[]
  width: number
  height: number
  finishDrawing: () => void
}

export default function Heatmap(props: HeatmapProps) {
  const { Canvas } = useCanvas<HeatmapDataEntry>({
    width: props.width,
    height: props.height,
    data: props.values,
    render: (ctx, data, width, height) => {
      //
      // The left legend can be contained from x: 0 to additionalWidth, y: topLegendHeight
      // The top legend can be contained from x: additionalWidth + 50, y: 0 to topLegendHeight
      //

      const additionalWidth = 200
      const baseWidth = width - additionalWidth

      const red = "#e74c3c"
      const orange = "#f39c12"
      const yellow = "#f1c40f"
      const green = "#27ae60"
      const grey = "#bdc3c7"
      const darkgrey = "#95a5a6"

      const dotSize = 3

      const leftCut = 70
      const topLegendHeight = 150

      const colWidth = (baseWidth - leftCut) / 3
      const rowHeight = (height - topLegendHeight) / 6

      const fontSize = 11

      // Render top legend
      ctx.fillStyle = grey
      ctx.fillRect(
        additionalWidth + leftCut,
        0,
        baseWidth - leftCut,
        topLegendHeight
      )

      // Render left legend
      ctx.fillStyle = grey
      ctx.fillRect(
        0,
        topLegendHeight,
        additionalWidth + leftCut,
        height - topLegendHeight
      )

      // Render heatmap
      ;(() => {
        // Row 1-2
        ctx.fillStyle = green
        ctx.fillRect(
          additionalWidth + leftCut,
          topLegendHeight,
          colWidth,
          rowHeight * 2
        )

        ctx.fillStyle = yellow
        ctx.fillRect(
          additionalWidth + leftCut + colWidth,
          topLegendHeight,
          colWidth,
          rowHeight * 2
        )

        ctx.fillStyle = orange
        ctx.fillRect(
          additionalWidth + leftCut + colWidth * 2,
          topLegendHeight,
          colWidth,
          rowHeight * 2
        )

        // Row 3
        ctx.fillStyle = yellow
        ctx.fillRect(
          additionalWidth + leftCut,
          topLegendHeight + rowHeight * 2,
          colWidth,
          rowHeight
        )

        ctx.fillStyle = orange
        ctx.fillRect(
          additionalWidth + leftCut + colWidth,
          topLegendHeight + rowHeight * 2,
          colWidth,
          rowHeight
        )

        ctx.fillStyle = red
        ctx.fillRect(
          additionalWidth + leftCut + colWidth * 2,
          topLegendHeight + rowHeight * 2,
          colWidth,
          rowHeight
        )

        // Row 4
        ctx.fillStyle = orange
        ctx.fillRect(
          additionalWidth + leftCut,
          topLegendHeight + rowHeight * 3,
          colWidth,
          rowHeight
        )

        ctx.fillStyle = red
        ctx.fillRect(
          additionalWidth + leftCut + colWidth,
          topLegendHeight + rowHeight * 3,
          colWidth * 2,
          rowHeight
        )

        // Row 5-6
        ctx.fillStyle = red
        ctx.fillRect(
          additionalWidth + leftCut,
          topLegendHeight + rowHeight * 4,
          colWidth * 3,
          rowHeight * 2
        )
      })()

      // Render grid
      ;(() => {
        function strokeLine(
          startX: number,
          startY: number,
          endX: number,
          endY: number
        ) {
          ctx.strokeStyle = darkgrey
          ctx.beginPath()
          ctx.moveTo(startX, startY)
          ctx.lineTo(endX, endY)
          ctx.closePath()
          ctx.stroke()
        }

        for (let i = 0; i < 4; i++)
          strokeLine(
            additionalWidth + leftCut + colWidth * i,
            i == 0 || i == 3 ? 0 : topLegendHeight / 3,
            additionalWidth + leftCut + colWidth * i,
            height + topLegendHeight
          )

        for (let i = 0; i < 7; i++)
          strokeLine(
            i == 0 || i == 6 ? 0 : topLegendHeight / 3,
            topLegendHeight + rowHeight * i,
            additionalWidth + baseWidth,
            topLegendHeight + rowHeight * i
          )

        strokeLine(additionalWidth + leftCut, 0, baseWidth + additionalWidth, 0)
        strokeLine(
          additionalWidth + leftCut,
          topLegendHeight / 3,
          baseWidth + additionalWidth,
          topLegendHeight / 3
        )
        strokeLine(
          additionalWidth + leftCut,
          topLegendHeight / 1.5,
          baseWidth + additionalWidth,
          topLegendHeight / 1.5
        )

        strokeLine(0, topLegendHeight, 0, height)
        strokeLine(
          topLegendHeight / 3,
          topLegendHeight,
          topLegendHeight / 3,
          height
        )
        strokeLine(additionalWidth, topLegendHeight, additionalWidth, height)

        // Render labels
        ;(() => {
          ctx.font = `${fontSize}px Verdana`
          ctx.fillStyle = "black"
          ctx.textAlign = "center"

          // ACR
          ctx.fillText(
            "Urine Albumin-Creatinine Ratio (Urine ACR)",
            additionalWidth + leftCut + (baseWidth - leftCut) / 2,
            topLegendHeight / 6 + fontSize / 2
          )

          ctx.fillText(
            "Normal to",
            additionalWidth + leftCut + colWidth * 0.5,
            topLegendHeight / 2 - fontSize / 4
          )
          ctx.fillText(
            "mildly increased",
            additionalWidth + leftCut + colWidth * 0.5,
            topLegendHeight / 2 + fontSize
          )
          ctx.fillText(
            "<30mg/g",
            additionalWidth + leftCut + colWidth * 0.5,
            topLegendHeight - 30
          )
          ctx.fillText(
            "<3mg/mmol",
            additionalWidth + leftCut + colWidth * 0.5,
            topLegendHeight - 15
          )

          ctx.fillText(
            "Moderately",
            additionalWidth + leftCut + colWidth * 1.5,
            topLegendHeight / 2 - fontSize / 4
          )
          ctx.fillText(
            "increased",
            additionalWidth + leftCut + colWidth * 1.5,
            topLegendHeight / 2 + fontSize
          )
          ctx.fillText(
            "30-300 mg/g",
            additionalWidth + leftCut + colWidth * 1.5,
            topLegendHeight - 30
          )
          ctx.fillText(
            "3-30 mg/mmol",
            additionalWidth + leftCut + colWidth * 1.5,
            topLegendHeight - 15
          )

          ctx.fillText(
            "Severely",
            additionalWidth + leftCut + colWidth * 2.5,
            topLegendHeight / 2 - fontSize / 4
          )
          ctx.fillText(
            "increased",
            additionalWidth + leftCut + colWidth * 2.5,
            topLegendHeight / 2 + fontSize
          )
          ctx.fillText(
            ">300 mg/g",
            additionalWidth + leftCut + colWidth * 2.5,
            topLegendHeight - 30
          )
          ctx.fillText(
            ">30 mg/mmol",
            additionalWidth + leftCut + colWidth * 2.5,
            topLegendHeight - 15
          )

          // eGFR
          ctx.save()
          ctx.rotate(Math.PI * 1.5)
          ctx.fillText(
            "Estimated Glomerular Filtration Rate (eGFR)",
            -(topLegendHeight + (height - topLegendHeight) / 2),
            topLegendHeight / 6 - fontSize / 2
          )
          ctx.fillText(
            "(values in mL/min/1.73m²)",
            -(topLegendHeight + (height - topLegendHeight) / 2),
            topLegendHeight / 6 + fontSize
          )
          ctx.restore()

          ctx.fillText(
            "Normal or high",
            topLegendHeight / 3 + (additionalWidth - topLegendHeight / 3) / 2,
            topLegendHeight + fontSize / 2 + rowHeight * 0.5
          )
          ctx.fillText(
            ">90",
            additionalWidth + leftCut / 2,
            topLegendHeight + fontSize / 2 + rowHeight * 0.5
          )
          ctx.fillText(
            "Mildly decreased",
            topLegendHeight / 3 + (additionalWidth - topLegendHeight / 3) / 2,
            topLegendHeight + fontSize / 2 + rowHeight * 1.5
          )
          ctx.fillText(
            "60-89",
            additionalWidth + leftCut / 2,
            topLegendHeight + fontSize / 2 + rowHeight * 1.5
          )
          ctx.fillText(
            "Mild to moderately",
            topLegendHeight / 3 + (additionalWidth - topLegendHeight / 3) / 2,
            topLegendHeight + fontSize / 2 + rowHeight * 2.5 - fontSize / 1.5
          )
          ctx.fillText(
            "decreased",
            topLegendHeight / 3 + (additionalWidth - topLegendHeight / 3) / 2,
            topLegendHeight + fontSize / 2 + rowHeight * 2.5 + fontSize / 1.5
          )
          ctx.fillText(
            "45-59",
            additionalWidth + leftCut / 2,
            topLegendHeight + fontSize / 2 + rowHeight * 2.5
          )
          ctx.fillText(
            "Moderately to",
            topLegendHeight / 3 + (additionalWidth - topLegendHeight / 3) / 2,
            topLegendHeight + fontSize / 2 + rowHeight * 3.5 - fontSize / 1.5
          )
          ctx.fillText(
            "severely decreased",
            topLegendHeight / 3 + (additionalWidth - topLegendHeight / 3) / 2,
            topLegendHeight + fontSize / 2 + rowHeight * 3.5 + fontSize / 1.5
          )
          ctx.fillText(
            "30-44",
            additionalWidth + leftCut / 2,
            topLegendHeight + fontSize / 2 + rowHeight * 3.5
          )
          ctx.fillText(
            "Severely decreased",
            topLegendHeight / 3 + (additionalWidth - topLegendHeight / 3) / 2,
            topLegendHeight + fontSize / 2 + rowHeight * 4.5
          )
          ctx.fillText(
            "15-29",
            additionalWidth + leftCut / 2,
            topLegendHeight + fontSize / 2 + rowHeight * 4.5
          )
          ctx.fillText(
            "Kidney failure",
            topLegendHeight / 3 + (additionalWidth - topLegendHeight / 3) / 2,
            topLegendHeight + fontSize / 2 + rowHeight * 5.5
          )
          ctx.fillText(
            "<15",
            additionalWidth + leftCut / 2,
            topLegendHeight + fontSize / 2 + rowHeight * 5.5
          )
        })()
      })()

      // Helper functions for rendering points on the heatmap
      function getX(acr: number) {
        acr = Math.max(Math.min(acr, 57), 0)
        let x = additionalWidth + leftCut

        if (acr >= 0 && acr < 3) x += (acr / 3) * colWidth
        else if (acr >= 3 && acr < 30)
          x += colWidth + ((acr - 3) / 27) * colWidth
        else if (acr >= 30 && acr <= 57)
          x += colWidth * 2 + ((acr - 30) / 27) * colWidth

        return x
      }

      function getY(egfr: number) {
        egfr = Math.max(Math.min(egfr, 105), 0)
        let y = topLegendHeight

        if (egfr <= 105 && egfr >= 90) y += (1 - (egfr - 90) / 15) * rowHeight
        else if (egfr < 90 && egfr >= 60)
          y += rowHeight + (1 - (egfr - 60) / 30) * rowHeight
        else if (egfr < 60 && egfr >= 45)
          y += rowHeight * 2 + (1 - (egfr - 45) / 15) * rowHeight
        else if (egfr < 45 && egfr >= 30)
          y += rowHeight * 3 + (1 - (egfr - 30) / 15) * rowHeight
        else if (egfr < 30 && egfr >= 15)
          y += rowHeight * 4 + (1 - (egfr - 15) / 15) * rowHeight
        else if (egfr < 15 && egfr >= 0)
          y += rowHeight * 5 + (1 - egfr / 15) * rowHeight

        return y
      }

      // Render lines between points
      data.forEach((v, i, a) => {
        if (i > 0) {
          const preX = getX(a[i - 1].acr)
          const preY = getY(a[i - 1].egfr)
          const curX = getX(v.acr)
          const curY = getY(v.egfr)

          ctx.strokeStyle = `#000000${Math.floor((1 - i / a.length) * 100)}`
          ctx.beginPath()
          ctx.moveTo(preX, preY)
          ctx.lineTo(curX, curY)
          ctx.closePath()
          ctx.stroke()
        }
      })

      // Render points
      data.forEach((v, i, a) => {
        const x = getX(v.acr)
        const y = getY(v.egfr)

        ctx.beginPath()
        ctx.arc(x, y, dotSize, 0, 2 * Math.PI, false)
        ctx.fillStyle = `#000000${Math.floor((1 - i / a.length) * 100)}`
        ctx.fill()

        if (v.isNew) {
          ctx.lineWidth = 1.5
          ctx.strokeStyle = "white"
          ctx.stroke()
        }
      })

      props.finishDrawing()
    }
  })

  return <Canvas />
}
