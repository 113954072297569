import { useState } from "react"
import { ActivityIndicator, Pressable, Text } from "react-native"

type PendingButtonProps = {
  onPress: () => Promise<void>
  title: string
  disabled?: boolean
}

export default function PendingButton(props: PendingButtonProps) {
  const [loading, setLoading] = useState(false)

  async function onPress() {
    setLoading(true)
    await props.onPress()
    setLoading(false)
  }

  return (
    <Pressable
      onPress={onPress}
      disabled={loading || props.disabled}
      className={`text-center ${
        loading || props.disabled ? "bg-gray-200" : "bg-[#2ecc71]"
      } rounded h-[65px] flex items-center justify-center shadow-lg`}
    >
      <Text
        className={`uppercase font-bold p-3 ${loading && "opacity-0"} ${
          props.disabled ? "text-gray-500" : "text-white"
        }`}
      >
        {props.title}
      </Text>
      <ActivityIndicator
        animating={loading}
        color="#2ecc71"
        className="absolute"
        size="large"
      />
    </Pressable>
  )
}
