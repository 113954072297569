import { NativeStackScreenProps } from "@react-navigation/native-stack"
import { Text, View } from "react-native"
import NavigationButton from "~/components/control/NavigationButton"
import ScreenContainer from "~/components/layout/ScreenContainer"
import ScrollableView from "~/components/layout/ScrollableView"
import { AuthStackParamList } from "~/navigation/AuthStack"

export default function ForgotPasswordSent({
  route,
  navigation
}: NativeStackScreenProps<AuthStackParamList, "RegisterComplete">) {
  const email = route.params!.email

  return (
    <ScreenContainer>
      <ScrollableView key={route.key}>
        <View className="p-5 flex flex-col justify-between min-h-[100vh]">
          <View>
            <View className="flex flec-col justify-center items-center my-5">
              <Text className="text-2xl">Password Recovery Sent</Text>
            </View>
            <View className="mb-3 mt-5">
              <Text>
                Please check your email inbox for a password recovery link.
                Follow this link to reset your password, then you can log back
                in with your new details.
              </Text>
            </View>
            <View className="mb-10">
              <Text>
                Email: <Text className="font-bold">{email}</Text>
              </Text>
            </View>
          </View>
          <View>
            <NavigationButton
              title="Proceed to Sign In"
              onPress={() => navigation.navigate("Login")}
            />
          </View>
        </View>
      </ScrollableView>
    </ScreenContainer>
  )
}
