import { useEffect, useState } from "react"
import { TextInput, TextInputProps } from "react-native"

type NumberInputProps = {
  value: number | null
  onChangeText: (e: number | null) => void
  int?: boolean
}

export default function NumberInput(
  props: Omit<TextInputProps, "value" | "onChangeText"> & NumberInputProps
) {
  // Handle writing text locally and save to external
  const [local, setLocal] = useState<string>()

  useEffect(() => {
    const external = props.value?.toString()
    setLocal(external === "NaN" ? "" : external)
  }, [props.value])

  function store(text: string) {
    const regex = props.int ? /[^\d]/g : /[^\d.]/g
    setLocal(text.replace(regex, ""))
  }

  function save(text: string) {
    const parse = props.int ? parseInt : parseFloat
    props.onChangeText(parse(text.replace(/[^\d.]/g, "")))
  }

  // Render component
  return (
    <TextInput
      {...props}
      value={local}
      onChangeText={e => store(e)}
      onBlur={e => save(e.nativeEvent.text)}
      className={`p-5 bg-[#eee] rounded-xl border-2 border-[#ddd] ${props.className}`}
      keyboardType="number-pad"
    />
  )
}
