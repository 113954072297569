import { Text } from "react-native"
import { Pressable } from "react-native"

type LinkButtonProps = {
  onPress: () => void
  title: string
  padding?: boolean
}

export default function LinkButton(props: LinkButtonProps) {
  return (
    <Pressable
      onPress={props.onPress}
      className={`inline max-w-min ${props.padding ? "p-2" : ""}`}
    >
      <Text className="text-blue-500 font-bold min-w-max inline-block">
        {props.title}
      </Text>
    </Pressable>
  )
}
