import { NativeStackScreenProps } from "@react-navigation/native-stack"
import { AuthStackParamList } from "~/navigation/AuthStack"
import { Linking, Text, View } from "react-native"
import { useState } from "react"
import useIdentityContext from "~/hooks/useIdentityContext"
import useApiContext from "~/hooks/useApiContext"
import { handleHttpErrors } from "~/utils/handleErrors"
import InputError from "~/components/InputError"
import PasswordTest from "~/components/PasswordTest"
import ScrollableView from "~/components/layout/ScrollableView"
import useLoading from "~/hooks/useLoading"
import LinkButton from "~/components/control/LinkButton"
import PendingButton from "~/components/control/PendingButton"
import Br from "~/components/layout/Br"
import Input from "~/components/control/Input"
import ScreenContainer from "~/components/layout/ScreenContainer"

export default function Register({
  route,
  navigation
}: NativeStackScreenProps<AuthStackParamList, "Register">) {
  const { register } = useApiContext()
  const { registered, setRegistered, loginToRegister, setLoginToRegister } =
    useIdentityContext()
  const { loading, setLoading, LoadingView } = useLoading()

  const [email, setEmail] = useState(route.params.email ?? "")
  const [password, setPassword] = useState(route.params.password ?? "")

  const [errors, setErrors] = useState({
    I0: false,
    I1: false,
    I2: false,
    I3: false,
    I4: false,
    I5: false,
    I6: false,
    HTTP409: false,
    HTTP500: false
  })

  async function onSubmit() {
    await register(email, password)
      .then(async () => {
        await setRegistered(true)

        navigation.replace("RegisterComplete", {
          email: email.toLowerCase()
        })
      })
      .catch(err => setErrors(handleHttpErrors(errors, err)))
  }

  navigation.addListener("focus", async () => {
    if (loginToRegister) {
      await setLoginToRegister(null)
    } else {
      if (registered) navigation.replace("Login")
    }
    setLoading(false)
  })

  if (loading) return <LoadingView />

  return (
    <ScreenContainer>
      <ScrollableView key={route.key}>
        <View className="p-5 flex flex-col justify-between min-h-[100vh]">
          <View>
            {/* Title */}
            <View className="flex flec-col justify-center items-center my-5">
              <Text className="text-2xl">Welcome to</Text>
              <Text className="text-4xl">CKDFree</Text>
            </View>

            {/* Description */}
            <View className="my-5">
              <Text>
                To get started, let's create an account. Please enter an email
                that you have access to, and think of a strong password. Don't
                forget to save it somewhere safe so you don't lose it!
              </Text>
            </View>

            {/* Inputs */}
            <View>
              <Input
                type="EMAIL"
                required
                title="Email Address"
                placeholder="Enter your email address here"
                errors={[
                  [
                    errors.I6,
                    "The email you used doesn't seem to be valid. Please try again."
                  ]
                ]}
                value={email}
                setValue={e => setEmail(e)}
              />
              <Br small />

              <Input
                type="PASSWORD"
                required
                title="Password"
                placeholder="Enter a strong password here"
                errors={[
                  [
                    errors.I0 ||
                      errors.I1 ||
                      errors.I2 ||
                      errors.I3 ||
                      errors.I4 ||
                      errors.I5,
                    "The password you used doesn't seem to be valid. Please try again."
                  ]
                ]}
                value={password}
                setValue={e => setPassword(e)}
              />

              <View>
                <View className="mt-5">
                  <Text className="font-bold">
                    Your password must meet the following:
                  </Text>

                  <View className="flex flex-col gap-1 ml-3 mt-3">
                    <PasswordTest isValid={password.length >= 8}>
                      <Text>At least 8 characters long</Text>
                    </PasswordTest>
                    <PasswordTest isValid={/[A-Z]/.test(password)}>
                      <Text>Contain a capital letter</Text>
                    </PasswordTest>
                    <PasswordTest isValid={/[a-z]/.test(password)}>
                      <Text>Contain a lower case letter</Text>
                    </PasswordTest>
                    <PasswordTest isValid={/\d/.test(password)}>
                      <Text>Contain a number</Text>
                    </PasswordTest>
                    <PasswordTest isValid={/[^A-Za-z0-9]/.test(password)}>
                      <Text>Contain a special character</Text>
                    </PasswordTest>
                  </View>
                </View>
              </View>
              <Br />

              <View>
                <Text>
                  By signing up, you are agreeing to our{" "}
                  <LinkButton
                    onPress={() => Linking.openURL("https://ckdfree.com/terms")}
                    title="Terms and Conditions"
                  />{" "}
                  and{" "}
                  <LinkButton
                    onPress={() =>
                      Linking.openURL("https://ckdfree.com/privacy")
                    }
                    title="Privacy Policy"
                  />
                  .
                </Text>
              </View>

              <View className="mt-10">
                <PendingButton onPress={onSubmit} title="Sign Up" />
                <InputError visible={errors.HTTP409}>
                  An account already exists with this email. Do you mean to
                  login?
                </InputError>
                <InputError visible={errors.HTTP500}>
                  Something went wrong that hasn't been handled yet.
                </InputError>
              </View>
            </View>
          </View>

          {/* Login redirect */}
          <View className="mt-10 flex flex-col items-center">
            <Text>Already have an account?</Text>
            <LinkButton
              onPress={() => navigation.replace("Login", { email, password })}
              title="Login here"
              padding
            />
          </View>
        </View>
      </ScrollableView>
    </ScreenContainer>
  )
}
