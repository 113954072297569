import { NativeStackScreenProps } from "@react-navigation/native-stack"
import { AppStackParamList } from "~/navigation/AppStack"
import { useState } from "react"
import { Linking, Pressable, Text, View } from "react-native"
import Br from "~/components/layout/Br"
import ScrollableView from "~/components/layout/ScrollableView"
import { Token } from "@semifinals/token"
import useApiContext from "~/hooks/useApiContext"
import useIdentityContext from "~/hooks/useIdentityContext"
import useUserContext from "~/hooks/useUserContext"
import { handleHttpErrors } from "~/utils/handleErrors"
import InputError from "~/components/InputError"
import PendingButton from "~/components/control/PendingButton"
import Input from "~/components/control/Input"
import ScreenContainer from "~/components/layout/ScreenContainer"
import LinkButton from "~/components/control/LinkButton"

type Results = {
  firstName: string
  lastName: string
  dateOfBirth: Date | null
  sex: number | null
  ethnicity: number | null
  height: number | null
  weight: number | null
  hasKidneyDisease: boolean | null
  kidneyDiseaseType: number | null
  hasFamilyHistoryOfKD: boolean | null
  hasDiabetes: boolean | null
  hasCardiovascularDisease: boolean | null
  cardiovascularDiseaseType: number | null
  hasHighBloodPressure: boolean | null
  diet: number | null
  exerciseRoutine: number | null
  isAlcoholic: boolean | null
  alcoholAmount: string | null
  alcoholPreference: number | null
  isSmoker: boolean | null
}

export default function BasicInfoCollection({
  route,
  navigation
}: NativeStackScreenProps<AppStackParamList, "BasicInfoCollection">) {
  const { basicInfoAdd } = useApiContext()
  const { accessToken } = useIdentityContext()
  const { setUser, onboarding, setOnboarding } = useUserContext()

  const [results, setResults] = useState<Results>({
    firstName: route.params?.firstName ?? "",
    lastName: route.params?.lastName ?? "",
    dateOfBirth: route.params?.dateOfBirth
      ? new Date(route.params.dateOfBirth)
      : null,
    sex: route.params?.sex ?? null,
    ethnicity: route.params?.ethnicity ?? null,
    height: route.params?.height ?? null,
    weight: route.params?.weight ?? null,
    hasKidneyDisease: route.params?.hasKidneyDisease ?? null,
    kidneyDiseaseType: route.params?.kidneyDiseaseType ?? null,
    hasFamilyHistoryOfKD: route.params?.hasFamilyHistoryOfKD ?? null,
    hasDiabetes: route.params?.hasDiabetes ?? null,
    hasCardiovascularDisease: route.params?.hasCardiovascularDisease ?? null,
    cardiovascularDiseaseType: route.params?.cardiovascularDiseaseType ?? null,
    hasHighBloodPressure: route.params?.hasHighBloodPressure ?? null,
    diet: route.params?.diet ?? null,
    exerciseRoutine: route.params?.exerciseRoutine ?? null,
    isAlcoholic: route.params?.isAlcoholic ?? null,
    alcoholAmount: route.params?.alcoholAmount ?? null,
    alcoholPreference: route.params?.alcoholPreference ?? null,
    isSmoker: route.params?.isSmoker ?? null
  })

  const [errors, setErrors] = useState({
    U0: false,
    U1: false,
    U2: false,
    U3: false,
    U4: false,
    U5: false,
    U6: false,
    U7: false,
    U8: false,
    U9: false,
    U10: false,
    U11: false,
    U12: false,
    U13: false,
    U14: false,
    U15: false,
    U16: false,
    U17: false,
    U18: false,
    U19: false,
    HTTP500: false
  })

  async function submitAnswers() {
    await basicInfoAdd(
      Token.getId(accessToken)!,
      results.firstName,
      results.lastName,
      results.dateOfBirth,
      results.sex,
      results.ethnicity,
      results.height,
      results.weight,
      results.hasKidneyDisease,
      results.kidneyDiseaseType,
      results.hasFamilyHistoryOfKD,
      results.hasDiabetes,
      results.hasCardiovascularDisease,
      results.cardiovascularDiseaseType,
      results.hasHighBloodPressure,
      results.diet,
      results.exerciseRoutine,
      results.isAlcoholic,
      results.alcoholAmount,
      results.alcoholPreference,
      results.isSmoker
    )
      .then(async res => {
        await setOnboarding(false)
        await setUser(res)
        navigation.popToTop()
      })
      .catch(err => setErrors(handleHttpErrors(errors, err)))
  }

  return (
    <ScreenContainer>
      <ScrollableView key={route.key}>
        <View className="p-5">
          <View>
            {onboarding && (
              <>
                <Text className="text-2xl mb-3">Before we get started...</Text>
                <Text>
                  In order to provide the best service possible, we need to
                  learn a little bit about you first. For more information on
                  why we need this data and how we use it, please refer to our{" "}
                  <LinkButton
                    onPress={() =>
                      Linking.openURL("https://ckdfree.com/privacy")
                    }
                    title="privacy policy"
                  />
                  .
                </Text>
              </>
            )}
            <Text>
              Please fill in all questions marked with an asterisk as they are
              required.
            </Text>
          </View>

          <View className="pt-10">
            <Input
              type="TEXT"
              required
              title="First Name"
              placeholder="Enter first name here"
              errors={[[errors.U0, "Please enter a valid first name."]]}
              value={results.firstName}
              setValue={e => setResults(res => ({ ...res, firstName: e }))}
            />
            <Br small />

            <Input
              type="TEXT"
              required
              title="Last Name"
              placeholder="Enter last name here"
              errors={[[errors.U1, "Please enter a valid last name."]]}
              value={results.lastName}
              setValue={e => setResults(res => ({ ...res, lastName: e }))}
            />
            <Br small />

            <Input
              type="DATE"
              required
              title="Date of Birth"
              errors={[[errors.U2, "Please enter a valid date of birth."]]}
              value={results.dateOfBirth}
              setValue={e => setResults(res => ({ ...res, dateOfBirth: e }))}
              ignore={["DATE"]}
            />
            <Br small />

            <Input
              type="DROPDOWN"
              required
              title="Sex"
              errors={[[errors.U3, "Please select an option from above."]]}
              value={results.sex}
              values={["Male", "Female", "Other", "Prefer not to answer"]}
              setValue={e => setResults(res => ({ ...res, sex: e }))}
              defaultValue={results.sex}
            />
            <Br small />

            <Input
              type="DROPDOWN"
              required
              title="Do you identify as Aboriginal or Torres Strait Islander?"
              errors={[[errors.U4, "Please select an option from above."]]}
              value={results.ethnicity}
              values={[
                "Neither",
                "Aboriginal",
                "Torres Strait Islander",
                "Both"
              ]}
              setValue={e => setResults(res => ({ ...res, ethnicity: e }))}
              defaultValue={results.ethnicity}
            />
            <Br small />

            <Input
              type="NUMBER"
              required
              title="How tall are you (in centimetres)?"
              placeholder="Enter your height in centimetres (cm)"
              errors={[[errors.U5, "Please enter a valid height."]]}
              value={results.height}
              setValue={e => setResults(res => ({ ...res, height: e }))}
            />
            <Br small />

            <Input
              type="NUMBER"
              required
              title="What do you typically weigh (in kilograms)?"
              placeholder="Enter your weight in kilograms (kg)"
              errors={[[errors.U17, "Please enter a valid weight."]]}
              value={results.weight}
              setValue={e => setResults(res => ({ ...res, weight: e }))}
            />
            <Br small />

            <Input
              type="SEGMENTED"
              required
              title="Do you have kidney disease?"
              errors={[[errors.U10, "Please select an option from above."]]}
              value={
                results.hasKidneyDisease === null
                  ? -1
                  : results.hasKidneyDisease
                  ? 0
                  : 1
              }
              values={["Yes", "No"]}
              setValue={value => {
                if (value === 1)
                  setResults(res => ({ ...res, kidneyDiseaseType: null }))

                setResults(res => ({
                  ...res,
                  hasKidneyDisease: value === 0
                }))
              }}
            />
            <Br small />

            {results.hasKidneyDisease && (
              <>
                <Input
                  type="DROPDOWN"
                  required
                  title="What kind of kidney disease do you have?"
                  errors={[[errors.U18, "Please select an option from above."]]}
                  value={results.kidneyDiseaseType}
                  values={[
                    "Chronic kidney disease",
                    "Due to diabetes",
                    "Nephritis",
                    "Kidney stones",
                    "Other"
                  ]}
                  setValue={e =>
                    setResults(res => ({ ...res, kidneyDiseaseType: e }))
                  }
                  defaultValue={results.kidneyDiseaseType}
                />
                <Br />
              </>
            )}

            <Input
              type="SEGMENTED"
              required
              title="Do you have a family history of kidney disease?"
              errors={[[errors.U11, "Please select an option from above."]]}
              value={
                results.hasFamilyHistoryOfKD === null
                  ? -1
                  : results.hasFamilyHistoryOfKD
                  ? 0
                  : 1
              }
              values={["Yes", "No"]}
              setValue={value =>
                setResults(res => ({
                  ...res,
                  hasFamilyHistoryOfKD: value === 0
                }))
              }
            />
            <Br small />

            <Input
              type="SEGMENTED"
              required
              title="Do you have diabetes?"
              errors={[[errors.U12, "Please select an option from above."]]}
              value={
                results.hasDiabetes === null ? -1 : results.hasDiabetes ? 0 : 1
              }
              values={["Yes", "No"]}
              setValue={value =>
                setResults(res => ({
                  ...res,
                  hasDiabetes: value === 0
                }))
              }
            />
            <Br small />

            <Input
              type="SEGMENTED"
              required
              title="Do you have cardiovascular disease?"
              errors={[[errors.U13, "Please select an option from above."]]}
              value={
                results.hasCardiovascularDisease === null
                  ? -1
                  : results.hasCardiovascularDisease
                  ? 0
                  : 1
              }
              values={["Yes", "No"]}
              setValue={value => {
                if (value === 1)
                  setResults(res => ({
                    ...res,
                    cardiovascularDiseaseType: null
                  }))

                setResults(res => ({
                  ...res,
                  hasCardiovascularDisease: value === 0
                }))
              }}
            />
            <Br small />

            {results.hasCardiovascularDisease && (
              <>
                <Input
                  type="DROPDOWN"
                  required
                  title="What kind of cardiovascular disease do you have?"
                  errors={[[errors.U19, "Please select an option from above."]]}
                  value={results.cardiovascularDiseaseType}
                  values={[
                    "Heart attack",
                    "Angina",
                    "Stents or bypass surgery",
                    "Other"
                  ]}
                  setValue={e =>
                    setResults(res => ({
                      ...res,
                      cardiovascularDiseaseType: e
                    }))
                  }
                  defaultValue={results.cardiovascularDiseaseType}
                />
                <Br />
              </>
            )}

            <Input
              type="SEGMENTED"
              required
              title="Do you have high blood pressure?"
              errors={[[errors.U16, "Please select an option from above."]]}
              value={
                results.hasHighBloodPressure === null
                  ? -1
                  : results.hasHighBloodPressure
                  ? 0
                  : 1
              }
              values={["Yes", "No"]}
              setValue={value =>
                setResults(res => ({
                  ...res,
                  hasHighBloodPressure: value === 0
                }))
              }
            />
            <Br small />

            <Input
              type="SEGMENTED"
              required
              title="How would you rate your diet, where 1 is bad and 5 is great?"
              errors={[[errors.U6, "Please select an option from above."]]}
              value={results.diet === null ? -1 : results.diet - 1}
              values={["1", "2", "3", "4", "5"]}
              setValue={value =>
                setResults(res => ({
                  ...res,
                  diet: value + 1
                }))
              }
            />
            <Br small />

            <Input
              type="CUSTOM"
              required
              title="What would best describe your current physical exercise routine?"
              errors={[[errors.U7, "Please select an option from above."]]}
            >
              <View className="flex flex-col text-center border-2 border-[#ddd] rounded-xl bg-[#eee] overflow-hidden">
                <View className="flex flex-row">
                  <Pressable
                    onPress={() =>
                      setResults(res => ({ ...res, exerciseRoutine: 0 }))
                    }
                    className={`flex-1 ${
                      results.exerciseRoutine === 0 && "bg-[#ddd]"
                    }`}
                  >
                    <View className="p-5">
                      <Text>Sedetary</Text>
                      <Text>Little to no exercise</Text>
                    </View>
                  </Pressable>
                  <Pressable
                    onPress={() =>
                      setResults(res => ({ ...res, exerciseRoutine: 1 }))
                    }
                    className={`flex-1 ${
                      results.exerciseRoutine === 1 && "bg-[#ddd]"
                    }`}
                  >
                    <View className="p-5">
                      <Text>Light</Text>
                      <Text>Light exercise/sports 1-3 days a week</Text>
                    </View>
                  </Pressable>
                </View>
                <View className="flex flex-row">
                  <Pressable
                    onPress={() =>
                      setResults(res => ({ ...res, exerciseRoutine: 2 }))
                    }
                    className={`flex-1 ${
                      results.exerciseRoutine === 2 && "bg-[#ddd]"
                    }`}
                  >
                    <View className="p-5">
                      <Text>Moderate</Text>
                      <Text>Moderate exercise 3-5 days a week</Text>
                    </View>
                  </Pressable>
                  <Pressable
                    onPress={() =>
                      setResults(res => ({ ...res, exerciseRoutine: 3 }))
                    }
                    className={`flex-1 ${
                      results.exerciseRoutine === 3 && "bg-[#ddd]"
                    }`}
                  >
                    <View className="p-5">
                      <Text>Intense</Text>
                      <Text>Intense activity daily</Text>
                    </View>
                  </Pressable>
                </View>
              </View>
            </Input>
            <Br small />

            <Input
              type="SEGMENTED"
              required
              title="Do you currently drink regularly?"
              errors={[[errors.U14, "Please select an option from above."]]}
              value={
                results.isAlcoholic === null ? -1 : results.isAlcoholic ? 0 : 1
              }
              values={["Yes", "No"]}
              setValue={value => {
                if (value === 1) {
                  setResults(res => ({
                    ...res,
                    alcoholAmount: null,
                    alcoholPreference: null
                  }))
                }

                setResults(res => ({
                  ...res,
                  isAlcoholic: value === 0
                }))
              }}
            />
            <Br small />

            {!!results.isAlcoholic && (
              <>
                {(() => {
                  const options = ["0", "1-2", "3-4", "5-6", "7"]

                  return (
                    <Input
                      type="SEGMENTED"
                      required
                      title="How many days a week would you typically drink alcohol?"
                      errors={[
                        [errors.U8, "Please select an option from above."]
                      ]}
                      value={
                        results.hasHighBloodPressure === null
                          ? -1
                          : options.indexOf(results.alcoholAmount)
                      }
                      values={options}
                      setValue={value =>
                        setResults(res => ({
                          ...res,
                          alcoholAmount: options[value]
                        }))
                      }
                    />
                  )
                })()}
                <Br small />

                <Input
                  type="DROPDOWN"
                  required
                  title="What kind of alcohol do you mainly drink?"
                  errors={[[errors.U9, "Please select an option from above."]]}
                  value={results.alcoholPreference}
                  values={["Wine", "Beer", "Spirits", "Cider", "Other"]}
                  setValue={e =>
                    setResults(res => ({ ...res, alcoholPreference: e }))
                  }
                  defaultValue={results.alcoholPreference}
                />
                <Br />
              </>
            )}

            <Input
              type="SEGMENTED"
              required
              title="Do you smoke cigarettes or anything else?"
              errors={[[errors.U15, "Please select an option from above."]]}
              value={results.isSmoker === null ? -1 : results.isSmoker ? 0 : 1}
              values={["Yes", "No"]}
              setValue={value =>
                setResults(res => ({
                  ...res,
                  isSmoker: value === 0
                }))
              }
            />
            <Br />

            <View>
              <PendingButton title="Submit Answers" onPress={submitAnswers} />
              <InputError visible={errors.HTTP500}>
                Something went wrong that hasn't been handled yet.
              </InputError>
            </View>
          </View>
        </View>
      </ScrollableView>
    </ScreenContainer>
  )
}
