import { View } from "react-native"

type PasswordTestProps = {
  children?: React.ReactNode
  isValid: boolean
}

export default function PasswordTest(props: PasswordTestProps) {
  return (
    <View className="flex flex-row gap-3 items-center">
      <View
        className={`h-3 w-3 rounded-full ${
          props.isValid ? "bg-green-500" : "bg-red-500"
        }`}
      />
      {props.children}
    </View>
  )
}
