import React, { useEffect, useState } from "react"
import { SafeAreaView, ScrollView } from "react-native"

type ScrollableViewProps = {
  children?: React.ReactNode
  key: string
}

export default function ScrollableView(props: ScrollableViewProps) {
  return (
    <SafeAreaView className="flex-1" key={props.key}>
      <ScrollView>{props.children}</ScrollView>
    </SafeAreaView>
  )
}
