import { NativeStackScreenProps } from "@react-navigation/native-stack"
import { AuthStackParamList } from "~/navigation/AuthStack"
import { Text, View } from "react-native"
import { useState } from "react"
import useApiContext from "~/hooks/useApiContext"
import useIdentityContext from "~/hooks/useIdentityContext"
import { handleHttpErrors } from "~/utils/handleErrors"
import InputError from "~/components/InputError"
import ScrollableView from "~/components/layout/ScrollableView"
import PendingButton from "~/components/control/PendingButton"
import LinkButton from "~/components/control/LinkButton"
import Input from "~/components/control/Input"
import Br from "~/components/layout/Br"
import ScreenContainer from "~/components/layout/ScreenContainer"

export default function Login({
  route,
  navigation
}: NativeStackScreenProps<AuthStackParamList, "Login">) {
  const { login } = useApiContext()
  const { setAuth, setRegistered, setLoginToRegister } = useIdentityContext()

  const [email, setEmail] = useState(route.params.email ?? "")
  const [password, setPassword] = useState(route.params.password ?? "")

  const [errors, setErrors] = useState({
    I0: false,
    I1: false,
    I2: false,
    I3: false,
    I4: false,
    I5: false,
    I6: false,
    HTTP401: false,
    HTTP403: false,
    HTTP500: false
  })

  async function onSubmit() {
    await login(email, password)
      .then(async res => {
        await setRegistered(true)
        await setAuth(res)
      })
      .catch(err => setErrors(handleHttpErrors(errors, err)))
  }

  return (
    <ScreenContainer>
      <ScrollableView key={route.key}>
        <View className="p-5 flex flex-col justify-between min-h-[100vh]">
          <View>
            {/* Title */}
            <View className="flex flec-col justify-center items-center my-5">
              <Text className="text-2xl">Welcome back to</Text>
              <Text className="text-4xl">CKDFree</Text>
            </View>

            {/* Inputs */}
            <View>
              <Input
                type="EMAIL"
                required
                title="Email Address"
                placeholder="Enter your email address here"
                errors={[
                  [
                    errors.I6,
                    "The email you used doesn't seem to be valid. Please try again."
                  ]
                ]}
                value={email}
                setValue={e => setEmail(e)}
              />
              <Br small />

              <Input
                type="PASSWORD"
                required
                title="Password"
                placeholder="Enter your password here"
                errors={[
                  [
                    errors.I0 ||
                      errors.I1 ||
                      errors.I2 ||
                      errors.I3 ||
                      errors.I4 ||
                      errors.I5,
                    "The password you used doesn't seem to be valid. Please try again."
                  ]
                ]}
                value={password}
                setValue={e => setPassword(e)}
              />

              <LinkButton
                onPress={() => navigation.replace("ForgotPassword")}
                title="Forgot password?"
                padding
              />

              <View className="mt-10">
                <PendingButton onPress={onSubmit} title="Sign In" />
                <InputError visible={errors.HTTP401}>
                  The provided email and password don't match an account. Please
                  try again.
                </InputError>
                <InputError visible={errors.HTTP403}>
                  Please activate your account before trying to login. If you
                  don't see the email, check your spam folder.
                </InputError>
                <InputError visible={errors.HTTP500}>
                  Something went wrong that hasn't been handled yet.
                </InputError>
              </View>
            </View>
          </View>

          {/* Register redirect */}
          <View className="mt-10 flex flex-col items-center">
            <Text>Don't have an account?</Text>
            <LinkButton
              onPress={async () => {
                await setLoginToRegister(true)
                navigation.replace("Register", { email, password })
              }}
              title="Register here"
              padding
            />
          </View>
        </View>
      </ScrollableView>
    </ScreenContainer>
  )
}
