import { NativeWindStyleSheet } from "nativewind"
import { StatusBar } from "react-native"
import MainNavigation from "~/navigation/MainNavigation"
import ProviderStack from "./providers/ProviderStack"

NativeWindStyleSheet.setOutput({
  default: "native"
})

function App() {
  return (
    <ProviderStack>
      <StatusBar barStyle="default" />
      <MainNavigation />
    </ProviderStack>
  )
}

export default App
