import { Modal, Pressable, Text, View } from "react-native"
import Ionicons from "@expo/vector-icons/Ionicons"
import useIdentityContext from "~/hooks/useIdentityContext"
import useApiContext from "~/hooks/useApiContext"
import useUserContext from "~/hooks/useUserContext"
import { useState } from "react"
import NavigationButton from "../control/NavigationButton"

type HeaderProps = {
  children?: React.ReactNode
}

export default function Header(props: HeaderProps) {
  const { refreshToken, setAuth } = useIdentityContext()
  const { revoke } = useApiContext()
  const { setUser, setOnboarding } = useUserContext()

  const [modalVisible, setModalVisible] = useState(false)

  return (
    <View>
      <View className="flex flex-row items-top gap-4 justify-between">
        {props.children}

        <View className="max-w-min">
          <Pressable onPress={() => setModalVisible(true)}>
            <Ionicons name="exit-outline" size={30} color="#e74c3c" />
          </Pressable>
        </View>
      </View>
      <Modal
        transparent={true}
        visible={modalVisible}
        onRequestClose={() => setModalVisible(false)}
      >
        <Pressable
          className="w-full h-full"
          onPress={() => setModalVisible(false)}
        >
          <View className="flex justify-center items-center w-full h-full bg-[#00000044]">
            <View className="w-[250px] bg-[#ecf0f1] p-5 rounded-xl flex flex-col gap-y-3">
              <View className="mb-5">
                <Text>Are you sure you want to logout?</Text>
              </View>
              <View>
                <NavigationButton
                  title="Logout"
                  onPress={async () => {
                    setModalVisible(false)
                    revoke(refreshToken)
                    await setAuth(null)
                    await setUser(null)
                    await setOnboarding(false)
                  }}
                  enabledClassName="bg-[#e74c3c] text-sm max-h-[40px]"
                />
              </View>
              <View>
                <NavigationButton
                  title="Cancel"
                  onPress={() => setModalVisible(false)}
                  enabledClassName="bg-[#2d3436] text-sm max-h-[40px]"
                />
              </View>
            </View>
          </View>
        </Pressable>
      </Modal>
    </View>
  )
}
