import ApiProvider from "./ApiProvider"
import IdentityProvider from "./IdentityProvider"
import UserProvider from "./UserProvider"

type ProviderStackProps = {
  children?: React.ReactNode
}

export default function ProviderStack({ children }: ProviderStackProps) {
  return (
    <IdentityProvider>
      <UserProvider>
        <ApiProvider>{children}</ApiProvider>
      </UserProvider>
    </IdentityProvider>
  )
}
