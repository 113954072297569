// Calculated EGFR/ACR rating as per https://www.researchgate.net/figure/Prevalence-of-CKD-in-USA-based-on-ACR-and-eGFR-with-the-KDIGO-classification-system_fig1_277407334

export default class TestResultCalculator {
  /**
   * Caclulate the grade of a test result based on EGFR and ACR values.
   * @param egfr The user's EGFR
   * @param acr The user's ACR
   * @returns A grade represented by an enum which determines the severity
   */
  public static grade(
    egfr: number | null,
    acr: number | null
  ): TestResultGrade {
    // Don't grade if we don't have both values
    function isValid(value: number | null): boolean {
      return value === null || value === 0 || isNaN(value)
    }

    if (isValid(egfr) || isValid(acr)) return TestResultGrade.Unknown

    // Calculate grade
    function acrGrade(acr: number): number {
      if (acr <= 3) return 0
      if (acr <= 30) return 1
      return 2
    }

    function egfrGrade(egfr: number): number {
      if (egfr >= 60) return 0
      if (egfr >= 45) return 1
      if (egfr >= 30) return 2
      return 3
    }

    const grade = Math.min(acrGrade(acr) + egfrGrade(egfr), 3)

    // Return grade as enum
    return grade as TestResultGrade
  }
}

export enum TestResultGrade {
  Green,
  Yellow,
  Orange,
  Red,
  Unknown
}
