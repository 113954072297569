import { NativeStackScreenProps } from "@react-navigation/native-stack"
import { AppStackParamList } from "~/navigation/AppStack"
import { useEffect, useRef, useState } from "react"
import { Linking, ScrollView, Text, View } from "react-native"
import ScrollableView from "~/components/layout/ScrollableView"
import useApiContext from "~/hooks/useApiContext"
import useUserContext from "~/hooks/useUserContext"
import { ClinicalResult } from "~/types/ClinicalResult"
import NavigationButton from "~/components/control/NavigationButton"
import { DataTable } from "react-native-paper"
import Heatmap from "~/components/Heatmap"
import ScreenContainer from "~/components/layout/ScreenContainer"
import LinkButton from "~/components/control/LinkButton"
import Br from "~/components/layout/Br"
import TestResultCalculator, {
  TestResultGrade
} from "~/helpers/TestResultCalculator"

export default function ClinicalResultsView({
  route,
  navigation
}: NativeStackScreenProps<AppStackParamList, "ClinicalResultsView">) {
  const { clinicalResultGetById } = useApiContext()
  const { user } = useUserContext()

  const [results, setResults] = useState<ClinicalResult.Details[]>([])
  const [grade, setGrade] = useState<TestResultGrade>(TestResultGrade.Unknown)
  const [progress, setProgress] =
    useState<
      Record<
        "bloodPressureLower" | "bloodPressureUpper" | "bloodGlucose",
        number | null
      >
    >()

  useEffect(() => {
    if (user)
      clinicalResultGetById(user.id)
        .then(res => {
          setResults(res)

          if (res.length > 0)
            setGrade(TestResultCalculator.grade(res[0].egfr, res[0].acr))

          const details = [
            "bloodPressureLower",
            "bloodPressureUpper",
            "bloodGlucose"
          ]
            .map(type => {
              const values = res.find(r => r[type])
              if (!values) return null

              return [type, values[type]]
            })
            .filter(x => x !== null)
            .reduce(
              (pre, cur) => Object.assign(pre, { [cur[0]]: cur[1] }),
              {}
            ) as Record<
            "bloodPressureLower" | "bloodPressureUpper" | "bloodGlucose",
            number | null
          >

          setProgress(details)
        })
        .catch(err => console.log(err))
  }, [user])

  const view = useRef<View>(null)
  const scrollView = useRef<ScrollView>(null)
  const [width, setWidth] = useState<number>()
  const [finishedDrawing, setFinishedDrawing] = useState(false)

  useEffect(() => {
    view.current.measure((x, y, width, height) => setWidth(width))
  }, [])

  useEffect(() => {
    scrollView.current.scrollToEnd({ animated: false })
    scrollView.current.scrollTo({ x: width })
  }, [width, finishedDrawing, view, scrollView])

  function getGradeText(grade: TestResultGrade): string {
    switch (grade) {
      case TestResultGrade.Green:
        return "low"
      case TestResultGrade.Yellow:
        return "moderate"
      case TestResultGrade.Orange:
        return "high"
      case TestResultGrade.Red:
        return "very high"
      default:
        throw new Error("Invalid grade provided")
    }
  }

  function Done() {
    navigation.popToTop()
  }

  return (
    <ScreenContainer>
      <ScrollableView key={route.key}>
        <View className="p-5 flex flex-col justify-between min-h-[100vh]">
          <View>
            <View className={`p-5 rounded bg-gray-200 flex flex-col gap-3`}>
              <Text>
                These are your current results as per the most recent
                submissions made.
              </Text>
              {grade !== TestResultGrade.Unknown && (
                <Text>
                  According to your most recent submission, you are currently at
                  a{" "}
                  <Text className="font-bold">
                    {getGradeText(grade)} level risk
                  </Text>{" "}
                  for kidney disease.
                </Text>
              )}
            </View>
            <Br />

            <View className="flex flex-col gap-4">
              <View ref={view} className="w-full" />
              {/* Heatmap */}
              <View className="flex flex-row justify-center">
                <ScrollView
                  horizontal
                  className="w-[100%] max-w-[700px]"
                  ref={scrollView}
                  persistentScrollbar
                >
                  <View className="h-[500px]">
                    {/* <View> */}
                    <Heatmap
                      width={Math.max(Math.min(width ?? 0, 700), 550)}
                      height={500}
                      values={results
                        .filter(res => res.acr !== null && res.egfr !== null)
                        .sort((a, b) =>
                          new Date(a.date) > new Date(b.date) ? -1 : 1
                        )
                        .map((res, i) => ({
                          acr: res.acr,
                          egfr: res.egfr,
                          isNew: i === 0
                        }))}
                      finishDrawing={() => setFinishedDrawing(true)}
                    />
                    {/* </View> */}
                  </View>
                </ScrollView>
              </View>

              <View className="mb-4">
                <Text>
                  Green: low risk (if no other markers of kidney disease, no
                  CKD); Yellow: moderately increased risk; Orange: high risk;
                  Red: very high risk
                </Text>
                <LinkButton
                  onPress={() =>
                    Linking.openURL(
                      "https://kdigo.org/wp-content/uploads/2017/02/KDIGO_2012_CKD_GL.pdf"
                    )
                  }
                  title="Learn more"
                />
              </View>

              {/* Table for others */}
              {progress && Object.keys(progress).length > 0 && (
                <View className="mb-4">
                  <DataTable>
                    <DataTable.Header>
                      <DataTable.Title>
                        <Text>Result</Text>
                      </DataTable.Title>
                      <DataTable.Title>
                        <Text>Current</Text>
                      </DataTable.Title>
                    </DataTable.Header>
                    {Object.entries(progress).map(([key, value]) => (
                      <DataTable.Row key={key}>
                        <DataTable.Title>
                          <Text>
                            {((k: string) => {
                              switch (k) {
                                case "bloodPressureLower":
                                  return "Lower Blood Pressure"
                                case "bloodPressureUpper":
                                  return "Upper Blood Pressure"
                                case "bloodGlucose":
                                  return "Blood Glucose"
                                default:
                                  return "Unknown"
                              }
                            })(key)}
                          </Text>
                        </DataTable.Title>
                        <DataTable.Cell>
                          <Text>{value}</Text>
                        </DataTable.Cell>
                      </DataTable.Row>
                    ))}
                  </DataTable>
                </View>
              )}
            </View>

            <Br />
          </View>

          {/* Controls */}
          <View>
            <NavigationButton title="Done" onPress={Done} />
          </View>
        </View>
      </ScrollableView>
    </ScreenContainer>
  )
}
