import { createContext, useEffect, useState } from "react"
import { Auth } from "~/types/Auth"
import { LocalStorage, SessionStorage } from "~/utils/Storage"

export const IdentityContext = createContext<{
  accessToken: string
  accessTokenExpiry: number
  refreshToken: string
  setAuth: (auth?: Auth.Result) => Promise<void>
  registered: boolean
  setRegistered: (state: boolean) => Promise<void>
  loginToRegister: boolean
  setLoginToRegister: (state: boolean) => Promise<void>
}>(null)

type IdentityProviderProps = {
  children?: React.ReactNode
}

export default function IdentityProvider(props: IdentityProviderProps) {
  const [accessToken, setAccessToken] = useState<string>()
  const [accessTokenExpiry, setAccessTokenExpiry] = useState<number>()
  const [refreshToken, setRefreshToken] = useState<string>()
  const [registered, setRegistered] = useState(false)
  const [loginToRegister, setLoginToRegister] = useState<boolean>()

  useEffect(() => {
    ;(async () => {
      const localAccessToken = await SessionStorage.getItem("accessToken")
      setAccessToken(localAccessToken)

      const localAccessTokenExpiry = await SessionStorage.getItem(
        "accessTokenExpiry"
      )
      setAccessTokenExpiry(Number(localAccessTokenExpiry))

      const localRefreshToken = await LocalStorage.getItem("refreshToken")
      setRefreshToken(localRefreshToken)
    })()
  }, [])

  async function setAuth(auth?: Auth.Result) {
    if (auth === null) {
      try {
        await SessionStorage.removeItem("accessToken")
        await SessionStorage.removeItem("accessTokenExpiry")
        await LocalStorage.removeItem("refreshToken")

        setAccessToken(null)
        setAccessTokenExpiry(null)
        setRefreshToken(null)
      } catch (err) {
        console.log("error caught", err)
      }

      return
    }

    const expiry = Date.now() + auth.expires_in * 1000 - 10 * 1000 // Remove 10 seconds to ensure token is valid

    await SessionStorage.setItem("accessToken", auth.access_token)
    await SessionStorage.setItem("accessTokenExpiry", String(expiry))
    await LocalStorage.setItem("refreshToken", auth.refresh_token)

    setAccessToken(auth.access_token)
    setAccessTokenExpiry(expiry)
    setRefreshToken(auth.refresh_token)
  }

  async function setLocalRegistered(state: boolean) {
    if (!state) await LocalStorage.removeItem("registered")
    else await LocalStorage.setItem("registered", "true")
    setRegistered(state)
  }

  async function setLocalLoginToRegister(state: boolean) {
    if (!state) await SessionStorage.removeItem("loginToRegister")
    else await SessionStorage.setItem("loginToRegister", "true")
    setLoginToRegister(state)
  }

  return (
    <IdentityContext.Provider
      value={{
        accessToken,
        accessTokenExpiry,
        refreshToken,
        setAuth,
        registered,
        setRegistered: setLocalRegistered,
        loginToRegister,
        setLoginToRegister: setLocalLoginToRegister
      }}
    >
      {props.children}
    </IdentityContext.Provider>
  )
}
