import Ionicons from "@expo/vector-icons/Ionicons"
import { NativeStackScreenProps } from "@react-navigation/native-stack"
import { AppStackParamList } from "~/navigation/AppStack"
import { Image, Text, View } from "react-native"
import { useEffect, useState } from "react"
import useApiContext from "~/hooks/useApiContext"
import useIdentityContext from "~/hooks/useIdentityContext"
import { Token } from "@semifinals/token"
import useUserContext from "~/hooks/useUserContext"
import NavigationButton, {
  IconNavigationButton
} from "~/components/control/NavigationButton"
import useLoading from "~/hooks/useLoading"
import Streak from "~/components/Streak"
import Header from "~/components/layout/Header"
import Fact from "~/components/Fact"
import { useIsFocused } from "@react-navigation/native"
import ScreenContainer from "~/components/layout/ScreenContainer"
import ScrollableView from "~/components/layout/ScrollableView"
import React from "react"

export default function Home({
  route,
  navigation
}: NativeStackScreenProps<AppStackParamList, "Home">) {
  const { accessToken } = useIdentityContext()
  const { basicInfoGet } = useApiContext()
  const { user, setUser, setOnboarding, streak, setStreak, lastSubmission } =
    useUserContext()
  const { streakInfoGet } = useApiContext()
  const { loading, setLoading, LoadingView } = useLoading()
  const isFocused = useIsFocused()
  const [reqLoading, setReqLoading] = useState({
    streak: true,
    user: true
  })

  useEffect(() => {
    if (isFocused) {
      // Get user details, or direct them to onboarding
      if (!user)
        basicInfoGet(Token.getId(accessToken)!)
          .then(async res => await setUser(res))
          .catch(err => {
            if (err.status === 404) {
              setOnboarding(true)
              navigation.navigate("RequiredBasicInfoCollection")
            }
          })
          .finally(() => setReqLoading(v => ({ ...v, user: false })))
      else setReqLoading(v => ({ ...v, user: false }))

      // Get streak details
      if (
        !streak ||
        lastSubmission?.toDateString() !== new Date().toDateString()
      )
        streakInfoGet(Token.getId(accessToken), new Date().getTimezoneOffset())
          .then(async res => await setStreak(res, res.recent[0]))
          .finally(() => setReqLoading(v => ({ ...v, streak: false })))
      else setReqLoading(v => ({ ...v, streak: false }))
    }
  }, [isFocused])

  useEffect(() => {
    if (!reqLoading.streak && !reqLoading.user) setLoading(false)
  }, [reqLoading])

  if (loading) return <LoadingView />

  if (!streak || !user) return <LoadingView /> // This prevents errors trying to render below, but MIGHT cause a soft-lock in an untested scenario

  // Display normal home page
  return (
    <ScreenContainer>
      <ScrollableView key={route.key}>
        <View className="p-5">
          <Header>
            <Text className="text-2xl">Hello, {user.firstName}.</Text>
          </Header>

          <View className="flex flex-col gap-5 mt-10">
            <View>
              <Streak streak={streak.streakCurrent} completed={streak.recent} />
            </View>

            <View>
              <NavigationButton
                title={
                  streak.recent[0]
                    ? "Morning Review Completed"
                    : "Complete Morning Review"
                }
                onPress={() => navigation.navigate("MorningReview")}
                disabled={streak.recent[0]}
                rightNode={
                  streak.recent[0] && (
                    <Ionicons
                      name="checkmark-sharp"
                      size={24}
                      color="#2ecc71"
                    />
                  )
                }
              />
            </View>

            <View>
              <View className="my-5">
                <Fact categories={["Home"]} />
              </View>
            </View>

            <View className="flex flex-row">
              <NavigationButton
                title="Submit Clinical Test Results"
                onPress={() => navigation.navigate("ClinicalResults")}
                alwaysClassName="flex-1 mr-2"
              />
              <IconNavigationButton
                onPress={() => navigation.navigate("ClinicalResultsView")}
              >
                <Image
                  source={require("../../assets/history.png")}
                  className="w-8 h-8"
                />
              </IconNavigationButton>
            </View>

            <View>
              <NavigationButton
                title="Update Health Information"
                onPress={() => navigation.navigate("BasicInfoCollection", user)}
              />
            </View>

            <View>
              <NavigationButton
                title="for more information on chronic kidney disease"
                onPress={() => navigation.navigate("CKDInfoPage")}
              />
            </View>
          </View>
        </View>
      </ScrollableView>
    </ScreenContainer>
  )
}
