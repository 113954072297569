import { TextInput as NativeInput, TextInputProps } from "react-native"

export default function TextInput(props: TextInputProps) {
  return (
    <NativeInput
      {...props}
      className="p-5 bg-[#eee] rounded-xl border-2 border-[#ddd]"
    />
  )
}
