import AsyncStorage from "@react-native-async-storage/async-storage"

export class LocalStorage {
  public static async getItem(key: string): Promise<string | null> {
    try {
      return await AsyncStorage.getItem(key)
    } catch (err) {
      return null
    }
  }

  public static async setItem(key: string, value: string): Promise<void> {
    try {
      return await AsyncStorage.setItem(key, value)
    } catch (err) {}
  }

  public static async removeItem(key: string): Promise<void> {
    try {
      return await AsyncStorage.removeItem(key)
    } catch (err) {}
  }
}

export class SessionStorage {
  // TODO: Change so items stored in sessionStorage are deleted when session destroyed

  public static async getItem(key: string): Promise<string | null> {
    try {
      return await AsyncStorage.getItem(key)
    } catch (err) {
      return null
    }
  }

  public static async setItem(key: string, value: string): Promise<void> {
    try {
      return await AsyncStorage.setItem(key, value)
    } catch (err) {}
  }

  public static async removeItem(key: string): Promise<void> {
    try {
      return await AsyncStorage.removeItem(key)
    } catch (err) {}
  }
}
