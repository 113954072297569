import { View } from "react-native"

type ScreenContainerProps = {
  children?: React.ReactNode
}

export default function ScreenContainer({ children }: ScreenContainerProps) {
  return (
    <View className="w-full items-center">
      <View className="w-full max-w-[500px]">{children}</View>
    </View>
  )
}
