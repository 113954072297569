import { Pressable, Text, View } from "react-native"

type NavigationButtonProps = {
  onPress: () => void
  title: string
  disabled?: boolean
  rightNode?: React.ReactNode
  enabledClassName?: string
  alwaysClassName?: string
}

export default function NavigationButton(props: NavigationButtonProps) {
  return (
    <Pressable
      onPress={props.onPress}
      className={`text-center ${
        !props.disabled
          ? `bg-[#2ecc71] ${
              props.enabledClassName ? props.enabledClassName : ""
            }`
          : "bg-gray-200"
      } rounded h-[65px] flex items-center justify-center shadow-lg ${
        props.alwaysClassName
      }`}
      disabled={props.disabled ?? false}
    >
      <View className="flex flex-row items-center">
        <Text
          className={`uppercase font-bold p-3 ${
            !props.disabled ? "text-white" : "text-gray-500"
          }`}
        >
          {props.title}
        </Text>
        {props.rightNode}
      </View>
    </Pressable>
  )
}

type IconNavigationButtonProps = {
  onPress: () => void
  alwaysClassName?: string
  children?: React.ReactNode
}

export function IconNavigationButton(props: IconNavigationButtonProps) {
  return (
    <Pressable
      onPress={props.onPress}
      className={`text-center bg-gray-300 rounded h-[65px] w-[65px] flex items-center justify-center shadow-lg ${props.alwaysClassName}`}
    >
      <View className="flex flex-row items-center">{props.children}</View>
    </Pressable>
  )
}
