import { Text } from "react-native"

type InputErrorProps = {
  children?: string
  visible: boolean
}

export default function InputError(props: InputErrorProps) {
  return (
    <Text className={`mx-2 mt-1 text-red-500 ${props.visible ? "" : "hidden"}`}>
      {props.children}
    </Text>
  )
}
