import { useEffect, useRef } from "react"
import { Platform, View } from "react-native"
import Canvas, { CanvasRenderingContext2D } from "react-native-canvas"

type Context2D = CanvasRenderingContext2D | globalThis.CanvasRenderingContext2D

type useCanvasProps<T> = {
  width: number
  height: number
  data: T[]
  render: (ctx: Context2D, data: T[], width: number, height: number) => void
}

export default function useCanvas<T>(props: useCanvasProps<T>) {
  const view = useRef<View>(null)
  const webCanvas = useRef<HTMLCanvasElement>(null)
  const nativeCanvas = useRef<Canvas>(null)

  // Render the canvas
  useEffect(() => {
    const canvas = nativeCanvas.current ?? webCanvas.current

    canvas.width = props.width
    canvas.height = props.height

    const ctx: Context2D = canvas.getContext("2d")

    // Draw diagram
    ctx.clearRect(0, 0, canvas.width, canvas.height)
    props.render(ctx, props.data, canvas.width, canvas.height)
  }, [props.width, props.height, props.data, view, webCanvas, nativeCanvas])

  return {
    Canvas: () => (
      <View ref={view} className="h-full w-full">
        {Platform.OS === "web" ? (
          <canvas ref={webCanvas} />
        ) : (
          <Canvas ref={nativeCanvas} />
        )}
      </View>
    )
  }
}
