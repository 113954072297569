import { useState } from "react"
import { ActivityIndicator, View } from "react-native"

const useLoading = () => {
  const [loading, setLoading] = useState(true)

  const LoadingView = () => (
    <View className="flex flex-col justify-center items-center w-full h-full">
      <ActivityIndicator animating={true} size="large" color="#2ecc71" />
    </View>
  )

  return { loading, setLoading, LoadingView }
}

export default useLoading
