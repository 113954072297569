import { createContext, useState } from "react"
import { User } from "~/types/User"
import { useEffect } from "react"
import { Token } from "@semifinals/token"
import useIdentityContext from "~/hooks/useIdentityContext"
import { LocalStorage, SessionStorage } from "~/utils/Storage"
import { StreakInfo } from "~/types/StreakInfo"

export const UserContext = createContext<{
  user: User.Details
  setUser: (state: User.Details) => Promise<void>
  onboarding: boolean
  setOnboarding: (state: boolean) => Promise<void>
  lastSubmission: Date
  setLastSubmission: (date: Date | null) => Promise<void>
  streak: StreakInfo.Details
  setStreak: (
    state: StreakInfo.Details,
    justCompleted?: boolean
  ) => Promise<void>
}>(null)

type UserProviderProps = {
  children?: React.ReactNode
}

export default function UserProvider(props: UserProviderProps) {
  const { accessToken } = useIdentityContext()

  const [onboarding, setOnboarding] = useState(false)
  const [user, setUser] = useState<User.Details>()
  const [streak, setStreak] = useState<StreakInfo.Details>()
  const [lastSubmission, setLastSubmission] = useState<Date>()

  useEffect(() => {
    ;(async () => {
      const localOnboarding = await SessionStorage.getItem("onboarding")
      setOnboarding(!!localOnboarding)

      const localUser = await SessionStorage.getItem("userDetails")
      setUser(JSON.parse(localUser))

      const localLastSubmission = await LocalStorage.getItem("lastSubmission")
      setLastSubmission(new Date(localLastSubmission))

      if (
        new Date(localLastSubmission).toDateString() ===
        new Date().toDateString()
      ) {
        const localStreak = await SessionStorage.getItem("streakDetails")
        setStreak(JSON.parse(localStreak))
      }
    })()
  }, [])

  async function setLocalUser(user: User.Details) {
    if (user === null) await SessionStorage.removeItem("userDetails")
    else await SessionStorage.setItem("userDetails", JSON.stringify(user))
    setUser(user)
  }

  async function setLocalStreak(
    streak: StreakInfo.Details,
    justCompleted: boolean = false
  ) {
    if (streak === null) await SessionStorage.removeItem("streakDetails")
    else await SessionStorage.setItem("streakDetails", JSON.stringify(streak))
    setStreak(streak)
    setLocalLastSubmission(justCompleted ? new Date() : null)
  }

  async function setLocalLastSubmission(date: Date | null) {
    if (date === null) await SessionStorage.removeItem("lastSubmission")
    else
      await SessionStorage.setItem("lastSubmission", new Date().toDateString())
    setLastSubmission(date)
  }

  async function setLocalOnboarding(state: boolean) {
    if (!state) await LocalStorage.removeItem("onboarding")
    else await LocalStorage.setItem("onboarding", Token.getId(accessToken))
    setOnboarding(state)
  }

  return (
    <UserContext.Provider
      value={{
        user,
        setUser: setLocalUser,
        onboarding,
        setOnboarding: setLocalOnboarding,
        lastSubmission,
        setLastSubmission: setLocalLastSubmission,
        streak,
        setStreak: setLocalStreak
      }}
    >
      {props.children}
    </UserContext.Provider>
  )
}
