export function errorHasCode(err: any, code: string): boolean {
  if (err.body)
    if (Array.isArray(err.body)) return err.body.some(e => e.code === code)

  return false
}

export function handleHttpErrors(
  errorsState: Record<string, boolean>,
  error: any,
  log: boolean = true
) {
  if (log) console.log(error)

  return Object.keys(errorsState).reduce(
    (acc, cur) => ({
      ...acc,
      [cur]:
        (cur.startsWith("HTTP") && cur.includes(error.status)) ||
        errorHasCode(error, cur)
    }),
    {}
  ) as any
}
